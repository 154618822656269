import { TFunction } from "i18next";
import * as yup from "yup";
import { NavigateFunction } from "react-router-dom";
import { CurrUserDetails } from "@/utils/currUserType";
import Verticals from "@/core/enums/verticals.enum";
import { needsToFillPlacement } from "@/utils/utilFunctions";
import { parse_query_params } from "@/mixpanel-tracking/utils";
import { Factory, Pages } from "@/mixpanel-tracking/hp/factory";
import { NotificationItemTracking } from "@/mixpanel-tracking/hp/notification-tracking";

export const UserTypes = {
    HP: 1,
    CP: 2,
};

export const LoginMethod = {
    OTP: 1,
    EmailAndPass: 2,
};

export const emailOrPhoneValidationSchema = (
    t: TFunction<"translation", undefined, "translation">
) => {
    return yup.object().shape({
        email_or_phone: yup
            .string()
            .required(t("validation.required"))
            .test("email_or_phone", t("login.hp.email_err"), function (value) {
                if (
                    /^\+?\d+$/.test(value) &&
                    !validatePhone(t).isValidSync(value)
                ) {
                    return this.createError({
                        message: t("login.hp.phone_err"),
                    });
                } else if (
                    !/^\+?\d+$/.test(value) &&
                    !validateEmail(t).isValidSync(value)
                ) {
                    return this.createError({
                        message: t("login.hp.email_err"),
                    });
                }
                return (
                    validateEmail(t).isValidSync(value) ||
                    validatePhone(t).isValidSync(value)
                );
            }),
    });
};

const validateEmail = (
    t: TFunction<"translation", undefined, "translation">
) => {
    return yup.string().email(t("login.hp.email_err"));
};

export const containsOnlyNumbersAndStarts05 = /^05\d+$/;

const validatePhone = (
    t: TFunction<"translation", undefined, "translation">
) => {
    return yup
        .string()
        .matches(containsOnlyNumbersAndStarts05, t("login.hp.phone_err"))
        .min(10)
        .max(10);
};

export const isValidPhone = (input: string) => {
    return containsOnlyNumbersAndStarts05.test(input);
};

export const handleHPNavigate = (
    navigate: NavigateFunction,
    hp: CurrUserDetails,
    from?: string
) => {
    if (!hp.company?.journey || hp.reset_password) {
        navigate(
            `/hp/journey?isBack=${!hp.company?.journey}&firstTime=${hp.reset_password}`
        );
    } else if (from) {
        navigate(from);
    } else {
        const company_verticals = hp.company!.active_subscription.verticals;
        const isSalesVerticalPresent = company_verticals.some((vertical) => {
            if (vertical.id === Verticals.Sales) {
                navigate("/hp/jobs", { replace: true });
                return true;
            }
            return false;
        });

        if (!isSalesVerticalPresent) {
            navigate("/hp/marketing/jobs", { replace: true });
        }
    }
};

export const handleGeneralNavigation = (
    userData: CurrUserDetails,
    navigate: NavigateFunction,
    from?: string
) => {
    if (userData.views === "hp") {
        handleHPNavigate(navigate, userData, from);
    } else {
        if (from && from !== "/") {
            navigate(from);
        } else {
            let link: string = userData?.views ?? "";
            switch (userData.views) {
                case "candidate":
                    link += "/jobs";
                    break;
                case "fast-tracker":
                    link = "candidate/ft-vetting-process";
            }
            navigate(link, { replace: true });
            if (needsToFillPlacement(userData)) {
                document.getElementById("complete-ur-profile")!.style.display =
                    "flex";
            }
        }
    }
};

export const checkAndTriggerNotificationAnalytics = (
    current_user: CurrUserDetails,
    on_login_from: object
) => {
    const query_params =
        Object.keys(on_login_from).length > 0
            ? on_login_from
            : parse_query_params();
    if (shouldSkipNotificationAnalytics(query_params.source)) {
        return;
    }
    const factory = new Factory(current_user);
    const notification_tracker = factory.get_tracker(
        Pages.NOTIFICATION_ITEM
    ) as NotificationItemTracking;
    const notificationData = {
        trigger_time: parseInt(query_params.trigger_time) * 1000,
    };
    const analyticsData = {
        job_id: query_params.job_id,
        employee_id: query_params.employee_id,
        candidate_id: query_params.candidate_id,
        category: query_params.category,
    };
    notification_tracker.click(
        notificationData,
        query_params.source,
        analyticsData
    );
};

const shouldSkipNotificationAnalytics = (source: string) => {
    const validSources = ["whatsApp", "email"];
    return !validSources.includes(source);
};
