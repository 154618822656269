import { CurrUserDetails } from "@/utils/currUserType";
import { captureEvent, Mixpanel } from "../Mixpanel";
import { free_trial_props, prefix_to_object } from "../utils";
import { getUser } from "@/contexts/UserContext";

export class SignUpRework {
    currUser: CurrUserDetails;
    constructor() {
        this.currUser = getUser();
    }
    demand_page_start = () => {
        captureEvent("Demand Form Started");
    };
    demand_page_end = (user: any, status: string) => {
        captureEvent("Demand Form Ended", {
            ...prefix_to_object(user, "hp_"),
            status: status,
        });
    };
    verification_page_start = (user: any) => {
        captureEvent("Signup Verification Page Started", {
            ...prefix_to_object(user, "hp_"),
            typeOfVerificationPage: "email",
        });
    };
    verification_page_end = (props: any) => {
        captureEvent("Signup Verification Page Ended", {
            ...prefix_to_object(props.user, "hp_"),
            verificationMethod: "email",
            attempts_before_verification: props.attempts_before_verification,
            resendings_before_verification: props.resend_count,
            redirected_to: props.redirected_to,
        });
    };
    otp_sent = (user: any) => {
        captureEvent("OTP sent", {
            ...prefix_to_object(user, "hp_"),
            typeOfVerificationPage: "email",
        });
    };
    otp_resend = (props: any) => {
        captureEvent("Resent OTP Requested", {
            ...prefix_to_object(props.user, "hp_"),
            Timer_before_resend: props.wait,
            Number_of_attempts: props.resend_count,
            typeOfVerificationPage: "email",
        });
    };

    vertical_page_start = (type: string) => {
        captureEvent("Vertical Page Started", {
            Type: type,
        });
    };
    vertical_page_end = (props: any) => {
        captureEvent("Vertical Page Ended", {
            selected_vertical: props.selected_vertical,
            specifc_vertical_textbox: props.specifc_vertical_textbox,
            converted: props.converted,
        });
    };
    apologize_vertical_page_start = (props: any) => {
        captureEvent("Apologize Signup Flow Page Started", {
            ...prefix_to_object(this.currUser, "hp_"),
            selected_vertical: props.selected_vertical,
            specifc_vertical_textbox: props.specifc_vertical_textbox,
        });
    };
    apologize_vertical_page_end = (props: any) => {
        captureEvent("Apologize Signup Flow Page Ended", {
            single_vertical: props.selected_vertical,
            Redirected_to: props.redirected_to,
        });
    };
    share_link_copied = () => {
        captureEvent("Share Link Copied");
    };
    short_job_started = (vertical: string) => {
        captureEvent("Short Job Creation Started", {
            ...prefix_to_object(this.currUser, "hp_"),
            vertical: vertical,
        });
    };
    short_job_question_end = (props: any) => {
        captureEvent(`Short Job ${props.question} Ended`, {
            ...prefix_to_object(this.currUser, "hp_"),
            [props.question]: props.value,
            vertical: props.vertical,
        });
    };
    short_job_review_started = (props: any) => {
        captureEvent("Short Job Review Page Started", {
            ...prefix_to_object(this.currUser, "hp_"),
            haveExtraSection: props.haveExtraSection,
            workplaceType: props.workplaceType,
            city: props.city,
            seniority: props.seniority,
            B2X: props.b2x,
            jobFunction: props.jobFunction,
        });
    };
    short_job_review_ended = () => {
        captureEvent("Short Job Review Page Ended", {
            ...prefix_to_object(this.currUser, "hp_"),
        });
    };
    short_job_extra_section_confirmed = (extractQuestion: string[]) => {
        captureEvent("Short Job Extra Section Confirmed", {
            ...prefix_to_object(this.currUser, "hp_"),
            What_is_extra: extractQuestion,
        });
    };
    short_job_stage_3_confirmed = () => {
        captureEvent("Short Job Stage 3 Confirmed", {
            ...prefix_to_object(this.currUser, "hp_"),
        });
    };
    job_created = (props: any) => {
        captureEvent("Job Created", {
            ...free_trial_props(this.currUser),
            ...prefix_to_object(this.currUser, "hp_"),
            ...prefix_to_object(
                props.createdJobValues,
                "form_submitted_details_"
            ),
            Source: props.source,
            Vertical: props.vertical,
        });
        Mixpanel.people.increment("numbers_jobs_created");
    };
}
