import { captureEvent } from "../Mixpanel";
import { prefix_to_object } from "../utils";
import { getUser } from "@/contexts/UserContext";

export class NotServedJtbdTracking {
    constructor() {
        this.currUser = getUser();
    }
    page_view = (source) => {
        captureEvent("Not served JTBD page viewed", {
            ...prefix_to_object(this.currUser, "hp_"),
            source: source || "",
        });
    };

    form_submitted = (createdJtbdValues, source) => {
        captureEvent("Not served JTBD submitted", {
            ...prefix_to_object(this.currUser, "hp_"),
            ...prefix_to_object(createdJtbdValues, "form_submitted_details_"),
            source: source || "",
        });
    };
}
