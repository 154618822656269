import { configureStore } from "@reduxjs/toolkit";
import filtersReducer from "./filters/filters.store";
import candidatesReducer from "./candidates.store";
import selectedVerticalReducer from "./verticals/selected-vertical.store"; // New Redux slice for selectedVertical
import generalReducer from "./general/general.store";
import snackbarReducer from "./snackbarSlice/snackbarSlice";
import { filtersLocalStorageMiddleware } from "@/core/stores/filters/filters.middleware";

import { verticalMiddleware } from "./verticals/vertical.middleware"; // New middleware for selectedVertical

const store = configureStore({
    reducer: {
        filters: filtersReducer,
        candidateList: candidatesReducer,
        selectedVertical: selectedVerticalReducer,
        general: generalReducer,
        snackbar: snackbarReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            filtersLocalStorageMiddleware,
            verticalMiddleware
        ),
});

export type RootStateType = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
