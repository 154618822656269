import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReactNode } from "react";

interface SnackbarState {
    content: ReactNode;
    isOpen: boolean;
}

const initialState: SnackbarState = {
    content: null,
    isOpen: false,
};

const snackbarSlice = createSlice({
    name: "snackbar",
    initialState,
    reducers: {
        showSnackbar: (state, action: PayloadAction<ReactNode>) => {
            state.content = action.payload;
            state.isOpen = true;
        },

        hideSnackbar: (state) => {
            state.content = null;
            state.isOpen = false;
        },
    },
});

export const { showSnackbar, hideSnackbar } = snackbarSlice.actions;
export default snackbarSlice.reducer;
