import { CurrUserDetails } from "@/utils/currUserType";
import React, {
    createContext,
    useContext,
    useState,
    Dispatch,
    SetStateAction,
    useEffect,
} from "react";
import { useGrowthBook } from "@growthbook/growthbook-react";
import { Mixpanel } from "@/mixpanel-tracking/Mixpanel";
import {
    free_trial_props,
    getSubscriptionDetails,
} from "@/mixpanel-tracking/utils";

const UserContext = createContext<CurrUserDetails | {}>({});
const UserSetContext = createContext<
    Dispatch<SetStateAction<CurrUserDetails | {}>>
>(() => {});

export const getUser = () => {
    return useContext(UserContext) as CurrUserDetails;
};

export const getSetUser = () => {
    return useContext(UserSetContext);
};

interface Props {
    children: React.ReactNode;
}

export const UserProvider = (props: Props) => {
    const [user, setUser] = useState<CurrUserDetails | {}>({});
    const growthbook = useGrowthBook();

    // Function to update user details and GrowthBook attributes
    const updateUserDetails = (newUserDetails: CurrUserDetails) => {
        setUser(newUserDetails);
    };

    // useEffect to log the latest user value whenever it updates
    useEffect(() => {
        const newUserDetails = user as CurrUserDetails;

        if (user && typeof user === "object" && Object.keys(user).length > 0) {
            // Update user attributes in GrowthBook
            growthbook?.setAttributes({
                id: newUserDetails.id,
                company_id: newUserDetails?.company?.id,
                // Add other user attributes as needed
            });

            // Updates subscription details and free trial related properties for all mixpanel events
            Mixpanel.register({
                ...getSubscriptionDetails(newUserDetails),
                ...free_trial_props(newUserDetails),
            });
        }

        // You can also perform other actions here if needed
    }, [user]); // This effect will run every time `user` changes

    return (
        <UserContext.Provider value={user}>
            <UserSetContext.Provider value={updateUserDetails}>
                {props.children}
            </UserSetContext.Provider>
        </UserContext.Provider>
    );
};
