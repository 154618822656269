import React from "react";
import { Lookup } from "@/models/payload/PlacementFormPayload";
import {
    CallOutlined,
    LocationOnOutlined,
    VideoCameraFrontOutlined,
} from "@mui/icons-material";
import { TFunction } from "i18next";
import * as yup from "yup";
import gmail from "@/../static/images/gmail.png";
import outlook from "@/../static/images/outlook.png";
import apple from "@/../static/images/apple.png";
import exchange from "@/../static/images/exchange.png";
import office from "@/../static/images/office365.png";
import dayjs, { Dayjs } from "dayjs";

export const time_length = [
    {
        id: 15,
        name_en: "15 minutes",
        name_ar: "15 دقيقة",
    },
    {
        id: 30,
        name_en: "30 minutes",
        name_ar: "30 دقيقة",
    },
    {
        id: 60,
        name_en: "60 minutes",
        name_ar: "60 دقيقة",
    },
];

export const interview_format = [
    {
        id: 1,
        name_en: "Online",
        name_ar: "عن بعد",
        icon: <VideoCameraFrontOutlined />,
    },
    {
        id: 2,
        name_en: "On-site",
        name_ar: "حضوري",
        icon: <LocationOnOutlined />,
    },
    {
        id: 3,
        name_en: "Phone",
        name_ar: "اتصال هاتفي",
        icon: <CallOutlined />,
    },
];

export interface InterviewDetails {
    length: Lookup;
    format: Lookup;
    office_location?: string;
    extra_details?: string;
    time_frame: number;
    query_periods: {
        start: Dayjs;
        end: Dayjs;
    };
}

export const schema = (
    t: TFunction<"translation", undefined, "translation">
) => {
    return yup.object().shape({
        length: yup
            .object()
            .shape({
                name: yup.string(),
                value: yup.number(),
            })
            .required(t("validation.required") as string),
        format: yup
            .object()
            .shape({
                name: yup.string(),
                value: yup.number(),
            })
            .required(t("validation.required") as string),
        office_location: yup
            .string()
            .test(
                "Check if Online is true",
                t("validation.required"),
                function () {
                    const format = this.parent["format"].id as number;
                    return !(
                        format === 2 && this.parent["office_location"] == ""
                    );
                }
            ),
        extra_details: yup.string(),
        query_periods: yup.object().shape({
            start: yup
                .date()
                .nullable()
                .required(t("validation.required") as string),
            end: yup
                .date()
                .nullable()
                .required(t("validation.required") as string),
        }),
    });
};

const scalers_availability_config_and_styles = (locale: string) => {
    return {
        config: {
            start_time: "08:30",
            end_time: "18:30",
            week_start_day: "saturday",
            duration: 30,
            auto_create_rules: true,
            default_weekly_periods: [
                {
                    day: "sunday",
                    start_time: "09:00",
                    end_time: "18:00",
                },
                {
                    day: "monday",
                    start_time: "09:00",
                    end_time: "18:00",
                },
                {
                    day: "tuesday",
                    start_time: "09:00",
                    end_time: "18:00",
                },
                {
                    day: "wednesday",
                    start_time: "09:00",
                    end_time: "18:00",
                },
                {
                    day: "thursday",
                    start_time: "09:00",
                    end_time: "18:00",
                },
            ],
        },
        styles: {
            colors: {
                primary: "#89599C",
                available: "#89599C",
                unavailable: "white",
                hairline: "#9D9CA0",
            },
        },
        translations: {
            ar: {
                availability_rules: {
                    save_new_rules: "حفظ الأوقات المتاحة",
                    rules_saved: "تم حفظ الأوقات الجديدة المتاحة بنجاح",
                },
            },
            en: {
                availability_rules: {
                    save_new_rules: "Save Availability",
                    rules_saved:
                        "The new available times have been successfully saved",
                },
            },
        },
        locale: locale,
        tzid: "Asia/Riyadh",
        data_center: "de",
    };
};

export const availability_options = (
    locale: string,
    token: string,
    callback: React.Dispatch<React.SetStateAction<AvailabilityPropType>>
) => {
    return {
        target_id: "cronofy-availability-rules",
        element_token: token,
        availability_rule_id: "default",
        ...scalers_availability_config_and_styles(locale),
        callback: callback,
    };
};

export const demo_availability = (locale: string) => {
    return {
        target_id: "cronofy-availability-rules",
        demo: true,
        ...scalers_availability_config_and_styles(locale),
        config: {
            start_time: "08:30",
            end_time: "18:30",
            week_start_day: "sunday",
        },
    };
};

export const get_email_image = (provider_name: string) => {
    switch (provider_name) {
        case "google":
            return gmail;
        case "apple":
            return apple;
        case "live_connect":
            return outlook;
        case "office365":
            return office;
        case "exchange":
            return exchange;
        default:
            return gmail;
    }
};

export const getInterviewTimeframeDate = (date: Dayjs): string => {
    return dayjs(date).format("YYYY-MM-DD");
};

export interface Calendar {
    provider_name: string;
    profile_name: string;
    profile_id: string;
    calendar_id: string;
}

interface WeeklyPeriod {
    day: string;
    start_time: string;
    end_time: string;
}

interface AvailabilityRule {
    availability_rule_id: string;
    tzid: string;
    weekly_periods: WeeklyPeriod[] | undefined;
    calendar_ids: string[];
}

export interface AvailabilityPropType {
    notification: { type: string };
    availability_rule?: AvailabilityRule | undefined;
}
