import Verticals from "@/core/enums/verticals.enum";
import { createSlice } from "@reduxjs/toolkit";

const loadSavedVertical = () => {
    const savedVertical = localStorage.getItem("selectedVertical");
    return savedVertical ? JSON.parse(savedVertical) : Verticals.Sales;
};

const initialState = {
    vertical: loadSavedVertical(),
};

const selectedVerticalSlice = createSlice({
    name: "selectedVertical",
    initialState,
    reducers: {
        setSelectedVertical: (state, action) => {
            state.vertical = action.payload;

            localStorage.setItem(
                "selectedVertical",
                JSON.stringify(action.payload)
            );
        },
    },
});

export const { setSelectedVertical } = selectedVerticalSlice.actions;
export default selectedVerticalSlice.reducer;
