import ImgTitleSubtitle from "@/components/ImgTitleSubtitle";
import { Button } from "@mui/material";
import React from "react";
import image from "@/../static/images/error-employee.svg";
import { useTranslation } from "react-i18next";

export default function EmployeesError() {
    const { t } = useTranslation();

    return (
        <div className="error-page">
            <div className="error-content">
                <ImgTitleSubtitle
                    title={t("error_page.title")}
                    img={image}
                    subtitle={t("error_page.subtitle")}
                />
                <Button
                    variant="contained"
                    onClick={() =>
                        window.location.replace(
                            `${window.location.origin}/hp/jobs`
                        )
                    }
                >
                    {t("error_page.button")}
                </Button>
            </div>
        </div>
    );
}
